.h-footer {text-align:center;}
.h-footerinline {text-align:center; }
.h-footerinline > * {display:inline-block;}
.h-footer-no{margin-left:10px; margin-right:10px;}
.f-logo{
    img{
        height:16px; 
        margin-left: 8px;
        margin-top: -3px;
    }
}
@primary-color: #1DA57A;