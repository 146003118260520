
.ant-tabs-nav-wrap{
    background: white;
    padding: 0px 2px;
}

.ant-tabs-nav{
    box-shadow: 0 5px 5px rgba(110,110,110,0.1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations{
    background: white;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    padding: 4px 10px;
    border: 1px solid rgba(24,155,255,0.2);
    border-bottom:none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more{
    padding: 4px 10px;
}

.my-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, 
.my-tabs.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
    border: 1px solid rgba(24,155,255,0.8);
    border-bottom:none;
    box-shadow: 2px -2px 2px rgba(110,110,110,0.3);
}

.dark .ant-tabs-nav-wrap{
    background: transparent;
    box-shadow: 10px 0px 10px rgba(110,110,110,0.3);
}

.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .dark 
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    border: 1px solid white;
    border-bottom:none;
}

.dark .my-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, 
.dark .my-tabs.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
    border: 1px solid white;
    border-bottom:none;
}

.dark .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, 
.dark .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations{
    background: transparent;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin-bottom: 10px;
}

@primary-color: #1DA57A;