.index-wrap{
    width: 100vw;
    height: calc(100vh - 150px );
    background: white;
}

.index-inner{
    margin: 0px auto;
    width: 1000px;
}

.timeline-clock-icon {
    font-size: 16px;
}

[data-theme='compact'] .timeline-clock-icon {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .index-inner{
        margin: 0px auto;
        width:100%;
    }
}
@primary-color: #1DA57A;