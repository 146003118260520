.h-header-wrap{
    width: 100vw;
    height: 45px; 
    border-bottom: 1px solid #ccc;
    box-shadow: 10px 0px 10px rgba(110,110,110,0.3);
    box-sizing:border-box;
    margin-bottom: 10px;
    
    h1 {
        margin: 0px;
        padding: 0px;
        font-size:24px;
        display:inline-block;
    }
}

.h-header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    margin: 0px auto;
    width: 1000px;
}
.h-left, .h-right{
    display:flex;
    align-items: center;
}
.h-logo{
    margin-right:15px;
    height: 30px; 
    img{height: 30px; overflow:hidden;}
}

.h-lang-txt {
    display: inline-block;
    padding: 0px 4px;
    border: solid 1px black;
    margin-top: -1px;
}

#x-menu .ant-menu-overflow-item{margin-bottom: -2px;}

@media screen and (max-width: 1000px) {
    body {
        .h-header{
            width: 100%;
        }
    }
}

@primary-color: #1DA57A;