
.x-header{
    padding:0px; 
    box-shadow: 0 5px 5px rgba(110,110,110,0.1);
}
.x-header-inline{
    height: 40px;
}
.x-header-row{
    height:100%;
}
.x-header-right{
    display: flex; 
    align-items: center; 
    justify-content: flex-end;
    padding-right:10px;
    .ant-btn-link{
        padding: 5px;
        width:auto;
        height:auto;
    }
}

.x-lang-txt{
    display:inline-block;
    padding:0px 4px; 
    border: solid 1px black;
}

.lang-h{
    display:inline;
    padding: 6px;
    margin-right: 5px;
}

.x-theme-style{display: inline-block; width: 10px; height:10px; margin-right:5px;  }
.x-dark-color{background: black; border: 1px solid white;}
.x-light-color{background: white; border: 1px solid black;}
.x-size-s{font-size: 12px; width:auto;height: auto; color: black; background: white;}
.x-size-m{font-size: 14px; width:auto;height: auto; color: black; background: white;}
.x-size-l{font-size: 18px; width:auto;height: auto; color: black; background: white;}

#logo-horizontal{
    img{height:50px;}
}


#logo-horizontal.ant-menu-submenu-selected::after{
    border:none !important;
}

.logo{text-align:center; padding-top:8px; padding-bottom:6px;
    img{height: 45px;}
}
.logo-collapse img{height:30px;}
@primary-color: #1DA57A;