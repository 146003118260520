.x-icon{
    font-style:normal;
    display: inline-block;
    margin-right: 5px;
}

// .dark .x-icon{
//     color: rgba(255,255,255,0.65);
// }


@primary-color: #1DA57A;