
.dark{
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(255, 255, 255, 0.85);
  }
  a {
    color: #177ddc;
  }
  a:hover {
    color: #165996;
  }
  a:active {
    color: #388ed3;
  }
  caption {
    color: rgba(255, 255, 255, 0.45);
  }
  mark {
    background-color: #2b2611;
  }
  ::-moz-selection {
    color: #fff;
    background: #177ddc;
  }
  ::selection {
    color: #fff;
    background: #177ddc;
  }
  html {
    --antd-wave-shadow-color: #177ddc;
  }
  [ant-click-animating-without-extra-node='true']::after,
  .ant-click-animating-node {
    box-shadow: 0 0 0 0 #177ddc;
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  }
  @-webkit-keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #177ddc;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #177ddc;
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  .ant-alert {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-alert-success {
    background-color: #162312;
  }
  .ant-alert-success .ant-alert-icon {
    color: #49aa19;
  }
  .ant-alert-info {
    background-color: #111b26;
  }
  .ant-alert-info .ant-alert-icon {
    color: #177ddc;
  }
  .ant-alert-warning {
    background-color: #2b2111;
  }
  .ant-alert-warning .ant-alert-icon {
    color: #d89614;
  }
  .ant-alert-error {
    background-color: #2a1215;
  }
  .ant-alert-error .ant-alert-icon {
    color: #a61d24;
  }
  .ant-alert-close-icon .anticon-close {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-alert-close-icon .anticon-close:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-alert-close-text {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-alert-close-text:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-alert-with-description .ant-alert-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-alert-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-anchor {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-anchor-ink::before {
    background-color: #303030;
  }
  .ant-anchor-ink-ball {
    background-color: #141414;
  }
  .ant-anchor-link-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-anchor-link-active > .ant-anchor-link-title {
    color: #177ddc;
  }
  .ant-select-auto-complete {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #141414;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: #595959;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-select {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #141414;
  }
  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-arrow {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-clear {
    color: rgba(255, 255, 255, 0.3);
    background: #141414;
  }
  .ant-select-clear:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-select-dropdown {
    color: rgba(255, 255, 255, 0.85);
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-select-dropdown-empty {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-item-empty {
    color: rgba(255, 255, 255, 0.85);
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(255, 255, 255, 0.85);
    background-color: #111b26;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #177ddc;
  }
  .ant-select-item-option-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-empty-normal {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-empty-small {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-empty-img-default-ellipse {
    fill: #fff;
  }
  .ant-empty-img-default-path-1 {
    fill: #262626;
  }
  .ant-empty-img-default-path-3 {
    fill: #595959;
  }
  .ant-empty-img-default-path-4 {
    fill: #434343;
  }
  .ant-empty-img-default-path-5 {
    fill: #595959;
  }
  .ant-empty-img-default-g {
    fill: #434343;
  }
  .ant-empty-img-simple-ellipse {
    fill: #fff;
  }
  .ant-empty-img-simple-g {
    stroke: #434343;
  }
  .ant-empty-img-simple-path {
    fill: #262626;
    stroke: #434343;
  }
  .ant-avatar {
    color: rgba(255, 255, 255, 0.85);
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-popover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-popover::after {
    background: rgba(255, 255, 255, 0.01);
  }
  .ant-popover-inner {
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.45) \9;
  }
  .ant-popover-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-popover-inner-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-popover-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-popover-message > .anticon {
    color: #d89614;
  }
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-back-top {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-back-top-content {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.45);
  }
  .ant-back-top-content:hover {
    background-color: rgba(255, 255, 255, 0.85);
  }
  .ant-badge {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-badge-count {
    color: #fff;
    background: #a61d24;
    box-shadow: 0 0 0 1px #141414;
  }
  .ant-badge-count a,
  .ant-badge-count a:hover {
    color: #fff;
  }
  .ant-badge-dot {
    background: #a61d24;
    box-shadow: 0 0 0 1px #141414;
  }
  .ant-badge-status-success {
    background-color: #49aa19;
  }
  .ant-badge-status-processing {
    background-color: #177ddc;
  }
  .ant-badge-status-default {
    background-color: #d9d9d9;
  }
  .ant-badge-status-error {
    background-color: #a61d24;
  }
  .ant-badge-status-warning {
    background-color: #d89614;
  }
  .ant-badge-status-pink {
    background: #cb2b83;
  }
  .ant-badge-status-magenta {
    background: #cb2b83;
  }
  .ant-badge-status-red {
    background: #d32029;
  }
  .ant-badge-status-volcano {
    background: #d84a1b;
  }
  .ant-badge-status-orange {
    background: #d87a16;
  }
  .ant-badge-status-yellow {
    background: #d8bd14;
  }
  .ant-badge-status-gold {
    background: #d89614;
  }
  .ant-badge-status-cyan {
    background: #13a8a8;
  }
  .ant-badge-status-lime {
    background: #8bbb11;
  }
  .ant-badge-status-green {
    background: #49aa19;
  }
  .ant-badge-status-blue {
    background: #177ddc;
  }
  .ant-badge-status-geekblue {
    background: #2b4acb;
  }
  .ant-badge-status-purple {
    background: #642ab5;
  }
  .ant-badge-status-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-ribbon {
    color: rgba(255, 255, 255, 0.85);
    color: #fff;
    background-color: #177ddc;
  }
  .ant-ribbon-text {
    color: #fff;
  }

  .ant-ribbon-corner::after {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-ribbon-color-pink {
    color: #cb2b83;
    background: #cb2b83;
  }
  .ant-ribbon-color-magenta {
    color: #cb2b83;
    background: #cb2b83;
  }
  .ant-ribbon-color-red {
    color: #d32029;
    background: #d32029;
  }
  .ant-ribbon-color-volcano {
    color: #d84a1b;
    background: #d84a1b;
  }
  .ant-ribbon-color-orange {
    color: #d87a16;
    background: #d87a16;
  }
  .ant-ribbon-color-yellow {
    color: #d8bd14;
    background: #d8bd14;
  }
  .ant-ribbon-color-gold {
    color: #d89614;
    background: #d89614;
  }
  .ant-ribbon-color-cyan {
    color: #13a8a8;
    background: #13a8a8;
  }
  .ant-ribbon-color-lime {
    color: #8bbb11;
    background: #8bbb11;
  }
  .ant-ribbon-color-green {
    color: #49aa19;
    background: #49aa19;
  }
  .ant-ribbon-color-blue {
    color: #177ddc;
    background: #177ddc;
  }
  .ant-ribbon-color-geekblue {
    color: #2b4acb;
    background: #2b4acb;
  }
  .ant-ribbon-color-purple {
    color: #642ab5;
    background: #642ab5;
  }
  .ant-breadcrumb {
    color: rgba(255, 255, 255, 0.85);
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-breadcrumb a {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-breadcrumb a:hover {
    color: #165996;
  }
  .ant-breadcrumb > span:last-child {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-breadcrumb > span:last-child a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-breadcrumb-separator {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-menu-item-danger.ant-menu-item {
    color: #a61d24;
  }
  .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-item-danger.ant-menu-item-active {
    color: #a61d24;
  }
  .ant-menu-item-danger.ant-menu-item:active {
    background: #2a1215;
  }
  .ant-menu-item-danger.ant-menu-item-selected {
    color: #a61d24;
  }
  .ant-menu-item-danger.ant-menu-item-selected > a,
  .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: #a61d24;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #2a1215;
  }
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #a61d24;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #a61d24;
  }
  .ant-menu {
    color: rgba(255, 255, 255, 0.85);
    background: #141414;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-menu.ant-menu-root:focus-visible {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-menu-item-group-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-menu-submenu-selected {
    color: #177ddc;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: #111b26;
  }
  .ant-menu-item a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-item a:hover {
    color: #177ddc;
  }
  .ant-menu-item > .ant-badge a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-item > .ant-badge a:hover {
    color: #177ddc;
  }
  .ant-menu-item-divider {
    background-color: #303030;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #177ddc;
  }
  .ant-menu-item-selected {
    color: #177ddc;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #177ddc;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #111b26;
  }
  .ant-menu-item:focus-visible,
  .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-menu > .ant-menu-item-divider {
    background-color: #303030;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #141414;
  }
  .ant-menu-submenu-popup > .ant-menu {
    background-color: #1f1f1f;
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #177ddc;
  }
  .ant-menu-vertical .ant-menu-submenu-selected,
  .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #177ddc;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #177ddc;
  }
  .ant-menu-horizontal > .ant-menu-item a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-horizontal > .ant-menu-item a:hover {
    color: #177ddc;
  }
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #177ddc;
  }
  .ant-menu.ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-sub.ant-menu-inline {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    color: rgba(255, 255, 255, 0.3) !important;
    background: none;
  }
  .ant-menu-item-disabled a,
  .ant-menu-submenu-disabled a {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #1f1f1f;
  }
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #141414;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #177ddc;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item:hover > a,
  .ant-menu-dark .ant-menu-item-active > a,
  .ant-menu-dark .ant-menu-submenu-active > a,
  .ant-menu-dark .ant-menu-submenu-open > a,
  .ant-menu-dark .ant-menu-submenu-selected > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ant-menu-dark .ant-menu-item:hover > span > a,
  .ant-menu-dark .ant-menu-item-active > span > a,
  .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #177ddc;
  }
  .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > span > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
  .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #177ddc;
  }
  .ant-menu-dark .ant-menu-item-disabled,
  .ant-menu-dark .ant-menu-submenu-disabled,
  .ant-menu-dark .ant-menu-item-disabled > a,
  .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-tooltip {
    color: rgba(255, 255, 255, 0.85);}
  .ant-tooltip-inner {
    color: #fff;
    background-color: #434343;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-tooltip-arrow-content {
    background-color: #434343;
  }
  .ant-tooltip-placement-top .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
  .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-tooltip-placement-left .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
  .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-tooltip-pink .ant-tooltip-inner {
    background-color: #cb2b83;
  }
  .ant-tooltip-pink .ant-tooltip-arrow-content {
    background-color: #cb2b83;
  }
  .ant-tooltip-magenta .ant-tooltip-inner {
    background-color: #cb2b83;
  }
  .ant-tooltip-magenta .ant-tooltip-arrow-content {
    background-color: #cb2b83;
  }
  .ant-tooltip-red .ant-tooltip-inner {
    background-color: #d32029;
  }
  .ant-tooltip-red .ant-tooltip-arrow-content {
    background-color: #d32029;
  }
  .ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #d84a1b;
  }
  .ant-tooltip-volcano .ant-tooltip-arrow-content {
    background-color: #d84a1b;
  }
  .ant-tooltip-orange .ant-tooltip-inner {
    background-color: #d87a16;
  }
  .ant-tooltip-orange .ant-tooltip-arrow-content {
    background-color: #d87a16;
  }
  .ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #d8bd14;
  }
  .ant-tooltip-yellow .ant-tooltip-arrow-content {
    background-color: #d8bd14;
  }
  .ant-tooltip-gold .ant-tooltip-inner {
    background-color: #d89614;
  }
  .ant-tooltip-gold .ant-tooltip-arrow-content {
    background-color: #d89614;
  }
  .ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #13a8a8;
  }
  .ant-tooltip-cyan .ant-tooltip-arrow-content {
    background-color: #13a8a8;
  }
  .ant-tooltip-lime .ant-tooltip-inner {
    background-color: #8bbb11;
  }
  .ant-tooltip-lime .ant-tooltip-arrow-content {
    background-color: #8bbb11;
  }
  .ant-tooltip-green .ant-tooltip-inner {
    background-color: #49aa19;
  }
  .ant-tooltip-green .ant-tooltip-arrow-content {
    background-color: #49aa19;
  }
  .ant-tooltip-blue .ant-tooltip-inner {
    background-color: #177ddc;
  }
  .ant-tooltip-blue .ant-tooltip-arrow-content {
    background-color: #177ddc;
  }
  .ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2b4acb;
  }
  .ant-tooltip-geekblue .ant-tooltip-arrow-content {
    background-color: #2b4acb;
  }
  .ant-tooltip-purple .ant-tooltip-inner {
    background-color: #642ab5;
  }
  .ant-tooltip-purple .ant-tooltip-arrow-content {
    background-color: #642ab5;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #a61d24;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #a61d24;
  }
  .ant-dropdown {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-dropdown-menu {
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-dropdown-menu-item-group-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-submenu-title > .anticon + span > a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected > a {
    color: #177ddc;
    background-color: #111b26;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ant-dropdown-menu-item-disabled > a,
  .ant-dropdown-menu-submenu-title-disabled > a {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-dropdown-menu-item-disabled > .anticon + span > a::after,
  .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a::after,
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background-color: #303030;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #177ddc;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #1f1f1f;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: #177ddc;
  }
  .ant-btn {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #165996;
  }
  .ant-btn:active {
    color: #388ed3;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-primary {
    color: #fff;
    background: #177ddc;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background: #095cb5;
  }
  .ant-btn-primary:active {
    color: #fff;
    background: #3c9be8;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-ghost {
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #165996;
  }
  .ant-btn-ghost:active {
    color: #388ed3;
  }

  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:hover,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-dashed {
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: #165996;
  }
  .ant-btn-dashed:active {
    color: #388ed3;
  }

  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-danger {
    background: #a61d24;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    color: #fff;
  }

  .ant-btn-danger:hover,
  .ant-btn-danger:focus {
    color: #fff;
    background: #800f19;
  }
  .ant-btn-danger:active {
    color: #fff;
    background: #b33b3d;
  }

  .ant-btn-danger[disabled],
  .ant-btn-danger[disabled]:hover,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-link {
    color: #177ddc;}

  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #165996;
  }
  .ant-btn-link:active {
    color: #388ed3;
  }

  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
  }

  .ant-btn-text {
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: #165996;
  }
  .ant-btn-text:active {
    color: #388ed3;
  }

  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.03);
  }
  .ant-btn-text:active {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
  }

  .ant-btn-dangerous {
    color: #a61d24;
  }

  .ant-btn-dangerous:hover,
  .ant-btn-dangerous:focus {
    color: #800f19;
  }
  .ant-btn-dangerous:active {
    color: #b33b3d;
  }

  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:hover,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background: #a61d24;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

  .ant-btn-dangerous.ant-btn-primary:hover,
  .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    background: #800f19;
  }
  .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    background: #b33b3d;
  }

  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-dangerous.ant-btn-link {
    color: #a61d24;
  }

  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #165996;
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #388ed3;
  }

  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #800f19;
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #b33b3d;
  }

  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
  }

  .ant-btn-dangerous.ant-btn-text {
    color: #a61d24;
  }

  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #165996;
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #388ed3;
  }

  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }

  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #800f19;
    background: rgba(255, 255, 255, 0.03);
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #b33b3d;
    background: rgba(255, 255, 255, 0.04);
  }

  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(255, 255, 255, 0.3);}

  .ant-btn::before {
    background: #141414;
  }
  .ant-btn .anticon.anticon-plus > svg,
  .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn-background-ghost {
    color: rgba(255, 255, 255, 0.85);
    background: transparent !important;
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: #177ddc;
  }

  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #095cb5;
  }
  .ant-btn-background-ghost.ant-btn-primary:active {
    color: #3c9be8;
  }

  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);}

  .ant-btn-background-ghost.ant-btn-danger {
    color: #a61d24;
  }

  .ant-btn-background-ghost.ant-btn-danger:hover,
  .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #800f19;
  }
  .ant-btn-background-ghost.ant-btn-danger:active {
    color: #b33b3d;
  }

  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);}

  .ant-btn-background-ghost.ant-btn-dangerous {
    color: #a61d24;
  }

  .ant-btn-background-ghost.ant-btn-dangerous:hover,
  .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #800f19;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #b33b3d;
  }

  .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);}

  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #a61d24;
  }

  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #800f19;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #b33b3d;
  }

  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);}

  .ant-picker-calendar {
    color: rgba(255, 255, 255, 0.85);
    background: #141414;
  }
  .ant-picker-calendar .ant-picker-panel {
    background: #141414;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    background: #141414;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #111b26;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #177ddc;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-group {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
  }
  .ant-radio-inner::after {
    background-color: #177ddc;
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .ant-radio-disabled + span {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-radio-button-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-button-wrapper a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #434343;
  }
  .ant-radio-button-wrapper:hover {
    color: #177ddc;
  }
  .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #177ddc;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #177ddc;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #165996;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #165996;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #388ed3;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: #388ed3;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #177ddc;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #165996;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #388ed3;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
  }
  .ant-radio-button-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-radio-button-wrapper-disabled:first-child,
  .ant-radio-button-wrapper-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.2);}
  .ant-picker {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-picker.ant-picker-disabled {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input > input {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-input > input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-input > input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-input > input:focus,
  .ant-picker-input > input-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-picker-input > input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-input > input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-input-placeholder > input {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-clear {
    color: rgba(255, 255, 255, 0.3);
    background: #141414;
  }
  .ant-picker-clear:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-picker-separator {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-focused .ant-picker-separator {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #177ddc;
  }
  .ant-picker-dropdown {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: #177ddc;
    background: #111b26;
  }
  .ant-picker-range-arrow {
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  }
  .ant-picker-panel-container {
    background: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-picker-panel {
    background: #1f1f1f;
  }
  .ant-picker-header {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-header button {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-header > button:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-header-view button:hover {
    color: #177ddc;
  }
  .ant-picker-content {
    table-layout: fixed;
  }
  .ant-picker-content th {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-cell {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-cell-in-view {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #111b26;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #177ddc;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #111b26;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #06213a;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: #06213a;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-cell-disabled::before {
    background: #303030;
  }
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: #303030;
  }
  .ant-picker-today-btn {
    color: #177ddc;
  }
  .ant-picker-today-btn:hover {
    color: #165996;
  }
  .ant-picker-today-btn:active {
    color: #388ed3;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
    background: transparent !important;
  }
  .ant-picker-week-panel-row:hover td {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #177ddc;
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(17, 27, 38, 0.2);
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #111b26;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tag {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tag-close-icon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-tag-close-icon:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tag-has-color,
  .ant-tag-has-color a,
  .ant-tag-has-color a:hover,
  .ant-tag-has-color .anticon-close,
  .ant-tag-has-color .anticon-close:hover {
    color: #fff;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #177ddc;
  }
  .ant-tag-checkable:active,
  .ant-tag-checkable-checked {
    color: #fff;
  }
  .ant-tag-checkable-checked {
    background-color: #177ddc;
  }
  .ant-tag-checkable:active {
    background-color: #388ed3;
  }
  .ant-tag-pink {
    color: #e0529c;
    background: #291321;
  }
  .ant-tag-pink-inverse {
    color: #fff;
    background: #cb2b83;
  }
  .ant-tag-magenta {
    color: #e0529c;
    background: #291321;
  }
  .ant-tag-magenta-inverse {
    color: #fff;
    background: #cb2b83;
  }
  .ant-tag-red {
    color: #e84749;
    background: #2a1215;
  }
  .ant-tag-red-inverse {
    color: #fff;
    background: #d32029;
  }
  .ant-tag-volcano {
    color: #e87040;
    background: #2b1611;
  }
  .ant-tag-volcano-inverse {
    color: #fff;
    background: #d84a1b;
  }
  .ant-tag-orange {
    color: #e89a3c;
    background: #2b1d11;
  }
  .ant-tag-orange-inverse {
    color: #fff;
    background: #d87a16;
  }
  .ant-tag-yellow {
    color: #e8d639;
    background: #2b2611;
  }
  .ant-tag-yellow-inverse {
    color: #fff;
    background: #d8bd14;
  }
  .ant-tag-gold {
    color: #e8b339;
    background: #2b2111;
  }
  .ant-tag-gold-inverse {
    color: #fff;
    background: #d89614;
  }
  .ant-tag-cyan {
    color: #33bcb7;
    background: #112123;
  }
  .ant-tag-cyan-inverse {
    color: #fff;
    background: #13a8a8;
  }
  .ant-tag-lime {
    color: #a9d134;
    background: #1f2611;
  }
  .ant-tag-lime-inverse {
    color: #fff;
    background: #8bbb11;
  }
  .ant-tag-green {
    color: #6abe39;
    background: #162312;
  }
  .ant-tag-green-inverse {
    color: #fff;
    background: #49aa19;
  }
  .ant-tag-blue {
    color: #3c9ae8;
    background: #111d2c;
  }
  .ant-tag-blue-inverse {
    color: #fff;
    background: #177ddc;
  }
  .ant-tag-geekblue {
    color: #5273e0;
    background: #131629;
  }
  .ant-tag-geekblue-inverse {
    color: #fff;
    background: #2b4acb;
  }
  .ant-tag-purple {
    color: #854eca;
    background: #1a1325;
  }
  .ant-tag-purple-inverse {
    color: #fff;
    background: #642ab5;
  }
  .ant-tag-success {
    color: #49aa19;
    background: #162312;
  }
  .ant-tag-processing {
    color: #177ddc;
    background: #111d2c;
  }
  .ant-tag-error {
    color: #d32029;
    background: #2a1215;
  }
  .ant-tag-warning {
    color: #d87a16;
    background: #2b1d11;
  }
  .ant-card {
    color: rgba(255, 255, 255, 0.85);
    background: #141414;
  }
  .ant-card-hoverable:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .ant-card-head {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-card-head .ant-tabs {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-card-extra {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-card-grid {
    box-shadow: 1px 0 0 0 #303030, 0 1px 0 0 #303030, 1px 1px 0 0 #303030, 1px 0 0 0 #303030 inset, 0 1px 0 0 #303030 inset;
  }
  .ant-card-grid-hoverable:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.64), 0 3px 6px 0 rgba(0, 0, 0, 0.48), 0 5px 12px 4px rgba(0, 0, 0, 0.36);
  }
  .ant-card-actions {
    background: #141414;
  }
  .ant-card-actions > li {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-card-actions > li > span:hover {
    color: #177ddc;
  }
  .ant-card-actions > li > span a:not(.ant-btn),
  .ant-card-actions > li > span > .anticon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-card-actions > li > span a:not(.ant-btn):hover,
  .ant-card-actions > li > span > .anticon:hover {
    color: #177ddc;
  }
  .ant-card-type-inner .ant-card-head {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-card-meta-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-card-meta-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-card-loading-block {
    background: linear-gradient(90deg, rgba(48, 48, 48, 0.2), rgba(48, 48, 48, 0.4), rgba(48, 48, 48, 0.2));
    background-size: 600% 600%;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-dropdown {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tabs-dropdown-menu {
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-tabs-dropdown-menu-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tabs-dropdown-menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-tabs-dropdown-menu-item-disabled,
  .ant-tabs-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #177ddc;
    background: #141414;
  }
  .ant-tabs {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
    color: #165996;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: #388ed3;
  }
  .ant-tabs-ink-bar {
    background: #177ddc;
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: #388ed3;
  }
  .ant-tabs-tab-remove {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-tabs-tab-remove:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tabs-tab:hover {
    color: #165996;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #177ddc;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-carousel .slick-dots li button {
    background: #141414;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #141414;
  }
  .ant-cascader {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-cascader-input.ant-input {
    background-color: transparent !important;
  }
  .ant-cascader-picker {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-cascader-picker-disabled {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-cascader-picker:focus .ant-cascader-input {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-cascader-picker-borderless .ant-cascader-input {
    box-shadow: none !important;
  }
  .ant-cascader-picker-show-search.ant-cascader-picker-focused {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-picker-clear {
    color: rgba(255, 255, 255, 0.3);
    background: #141414;
  }
  .ant-cascader-picker-clear:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-cascader-picker-arrow {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-menus {
    background: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-cascader-menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-cascader-menu-item-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-menu-empty .ant-cascader-menu-item {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color: #111b26;
  }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-loading-icon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
    color: #a61d24;
  }
  .ant-input-affix-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-affix-wrapper::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-input-affix-wrapper-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input-affix-wrapper[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input-clear-icon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-clear-icon:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-clear-icon:active {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input-group {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-group-addon {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #177ddc;
  }
  .ant-input-password-icon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-password-icon:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-textarea-show-count::after {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-inp.ant-checkbox {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #177ddc;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-checkbox-group {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #177ddc;
  }
  .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ant-collapse {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-collapse-content {
    color: rgba(255, 255, 255, 0.85);
    background-color: #141414;
  }
  .ant-collapse-borderless {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-comment-content-author-name {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-content-author-name > * {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-content-author-name > *:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-content-author-time {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-comment-actions > li {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-actions > li > span {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-comment-actions > li > span:hover {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-descriptions-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-descriptions-extra {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-descriptions-view table {
    table-layout: fixed;
  }
  .ant-descriptions-item-label {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-descriptions-item-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: auto;
  }
  .ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-divider {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-divider-horizontal.ant-divider-with-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-divider-dashed {
    background: none;
  }
  .ant-divider-plain.ant-divider-with-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.2), 12px 0 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
  .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
  }
  .ant-drawer-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-drawer-content {
    background-color: #1f1f1f;
  }
  .ant-drawer-close {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-drawer-close:focus,
  .ant-drawer-close:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-drawer-header {
    color: rgba(255, 255, 255, 0.85);
    background: #1f1f1f;
  }
  .ant-drawer-header-no-title {
    color: rgba(255, 255, 255, 0.85);
    background: #1f1f1f;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .ant-drawer-open-content {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-drawer .ant-picker-clear,
  .ant-drawer .ant-slider-handle,
  .ant-drawer .ant-anchor-wrapper,
  .ant-drawer .ant-collapse-content,
  .ant-drawer .ant-timeline-item-head,
  .ant-drawer .ant-transfer-list-header,
  .ant-drawer .ant-card {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-drawer tr.ant-table-expanded-row > td,
  .ant-drawer tr.ant-table-expanded-row:hover > td {
    background: #272727;
  }
  .ant-drawer .ant-table.ant-table-small thead > tr > th {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-table {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-table thead > tr > th {
    background-color: #272727;
  }
  .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-drawer .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-table tbody > tr.ant-table-row:hover > td {
    background: #303030;
  }
  .ant-drawer .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-drawer .ant-picker-calendar-full {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-picker-calendar-full .ant-picker-panel {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #1f1f1f;
  }
  .ant-drawer .ant-badge-count {
    box-shadow: 0 0 0 1px #1f1f1f;
  }
  .ant-drawer .ant-tree-show-line .ant-tree-switcher {
    background: #1f1f1f;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #a61d24;
  }
  .ant-form-item-explain.ant-form-item-explain-warning {
    color: #d89614;
  }
  .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #49aa19;
  }
  .ant-form-item-has-warning .ant-form-item-split {
    color: #d89614;
  }
  .ant-form-item-has-warning .ant-input:focus,
  .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
  .ant-form-item-has-warning .ant-input-focused,
  .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-disabled,
  .ant-form-item-has-warning .ant-input-disabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
  .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus,
  .ant-form-item-has-warning .ant-input-affix-wrapper-disabled:hover input:focus {
    box-shadow: none !important;
  }
  .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-prefix {
    color: #d89614;
  }
  .ant-form-item-has-warning .ant-input-group-addon {
    color: #d89614;
  }
  .ant-form-item-has-warning .has-feedback {
    color: #d89614;
  }
  .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #d89614;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
  .ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-number-focused,
  .ant-form-item-has-warning .ant-picker-focused,
  .ant-form-item-has-warning .ant-input-number:focus,
  .ant-form-item-has-warning .ant-picker:focus {
    box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
    box-shadow: 0 0 0 2px rgba(216, 150, 20, 0.2);
  }
  .ant-form-item-has-error .ant-form-item-split {
    color: #a61d24;
  }
  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-input-disabled,
  .ant-form-item-has-error .ant-input-disabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-form-item-has-error .ant-input-affix-wrapper-disabled,
  .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper-disabled:hover input:focus {
    box-shadow: none !important;
  }
  .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-input-prefix {
    color: #a61d24;
  }
  .ant-form-item-has-error .ant-input-group-addon {
    color: #a61d24;
  }
  .ant-form-item-has-error .has-feedback {
    color: #a61d24;
  }
  .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #a61d24;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-input-number:focus,
  .ant-form-item-has-error .ant-picker:focus {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
    box-shadow: 0 0 0 2px rgba(166, 29, 36, 0.2);
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #a61d24;
  }
  .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #177ddc;
  }
  .ant-form {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-form legend {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-form output {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-form-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-form-item-label > label {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #a61d24;
  }
  .ant-form-item-label > label .ant-form-item-optional {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-image-img-placeholder {
    background-color: #f5f5f5;
    background-repeat: no-repeat;
  }
  .ant-image-mask {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
  }
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .ant-image-preview-operations {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.1);
  }
  .ant-image-preview-operations-operation-disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.1);
  }
  .ant-image-preview-switch-left-disabled,
  .ant-image-preview-switch-right-disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .ant-input-number {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-input-number:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number:focus,
  .ant-input-number-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input-number[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }

  .ant-input-number-handler {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-number-handler:active {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #165996;
  }
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-input-number-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-input-number-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-handler-wrap {
    background: #141414;
  }
  .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-input-number-out-of-range input {
    color: #a61d24;
  }
  .ant-layout {
    background: #1f1f1f;
  }
  .ant-layout-header {
    color: rgba(255, 255, 255, 0.85);
    background: #1f1f1f;
  }
  .ant-layout-footer {
    color: rgba(255, 255, 255, 0.85);
    background: #000;
  }
  .ant-layout-sider {
    background: #1f1f1f;
  }
  .ant-layout-sider-trigger {
    color: #fff;
    background: #262626;
  }
  .ant-layout-sider-zero-width-trigger {
    color: #fff;
    background: #1f1f1f;
  }
  .ant-layout-sider-zero-width-trigger:hover::after {
    background: rgba(255, 255, 255, 0.1);
  }
  .ant-layout-sider-light {
    background: #fff;
  }
  .ant-layout-sider-light .ant-layout-sider-trigger {
    color: rgba(255, 255, 255, 0.85);
    background: #fff;
  }
  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgba(255, 255, 255, 0.85);
    background: #fff;
  }
  .ant-list {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-empty-text {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-list-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta-title > a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-list-item-meta-title > a:hover {
    color: #177ddc;
  }
  .ant-list-item-meta-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-list-item-action > li {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-list-item-action-split {
    background-color: #303030;
  }

  .ant-list-empty {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-list-vertical .ant-list-item-meta-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-spin {
    color: rgba(255, 255, 255, 0.85);
    color: #177ddc;
  }
  .ant-spin-container::after {
    background: #141414;
  }
  .ant-spin-tip {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-spin-dot-item {
    background-color: #177ddc;
  }
  .ant-pagination {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-pagination-item a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: #177ddc;
  }
  .ant-pagination-item-active a {
    color: #177ddc;
  }
  .ant-pagination-item-active:focus-visible a,
  .ant-pagination-item-active:hover a {
    color: #165996;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #177ddc;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #177ddc;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-pagination-options-quick-jumper input {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-pagination-options-quick-jumper input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: rgba(255, 255, 255, 0.25);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #000;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-mentions:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions:focus,
  .ant-mentions-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-mentions-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions[disabled] {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions-disabled > textarea {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions-focused {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-mentions > textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions > textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions-dropdown {
    color: rgba(255, 255, 255, 0.85);
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-mentions-dropdown-menu-item {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-mentions-dropdown-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-mentions-dropdown-menu-item-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-mentions-dropdown-menu-item-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
    background-color: #1f1f1f;
  }
  .ant-mentions-dropdown-menu-item-selected {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-mentions-dropdown-menu-item-active {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-message-notice-content {
    background: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-message-success .anticon {
    color: #49aa19;
  }
  .ant-message-error .anticon {
    color: #a61d24;
  }
  .ant-message-warning .anticon {
    color: #d89614;
  }
  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #177ddc;
  }
  .ant-modal {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .ant-modal-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-modal-content {
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-modal-close {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-modal-close:focus,
  .ant-modal-close:hover {
    color: rgba(255, 255, 255, 0.75);
  }
  .ant-modal-header {
    color: rgba(255, 255, 255, 0.85);
    background: #1f1f1f;
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #a61d24;
  }
  .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
  .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: #d89614;
  }
  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #177ddc;
  }
  .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #49aa19;
  }
  .ant-modal .ant-picker-clear,
  .ant-modal .ant-slider-handle,
  .ant-modal .ant-anchor-wrapper,
  .ant-modal .ant-collapse-content,
  .ant-modal .ant-timeline-item-head,
  .ant-modal .ant-card {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-transfer-list-header {
    background: #1f1f1f;
  }
  .ant-modal .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-modal tr.ant-table-expanded-row > td,
  .ant-modal tr.ant-table-expanded-row:hover > td {
    background: #272727;
  }
  .ant-modal .ant-table.ant-table-small thead > tr > th {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-table {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-table thead > tr > th {
    background-color: #272727;
  }
  .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-modal .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-table tbody > tr.ant-table-row:hover > td {
    background: #303030;
  }
  .ant-modal .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-modal .ant-picker-calendar-full {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-picker-calendar-full .ant-picker-panel {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #1f1f1f;
  }
  .ant-modal .ant-badge-count {
    box-shadow: 0 0 0 1px #1f1f1f;
  }
  .ant-modal .ant-tree-show-line .ant-tree-switcher {
    background: #1f1f1f;
  }
  .ant-notification .ant-picker-clear,
  .ant-notification .ant-slider-handle,
  .ant-notification .ant-anchor-wrapper,
  .ant-notification .ant-collapse-content,
  .ant-notification .ant-timeline-item-head,
  .ant-notification .ant-card {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-transfer-list-header {
    background: #1f1f1f;
  }
  .ant-notification .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-notification tr.ant-table-expanded-row > td,
  .ant-notification tr.ant-table-expanded-row:hover > td {
    background: #272727;
  }
  .ant-notification .ant-table.ant-table-small thead > tr > th {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-table {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-table thead > tr > th {
    background-color: #272727;
  }
  .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-left,
  .ant-notification .ant-table tbody > tr > td.ant-table-cell-fix-right {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-table tbody > tr.ant-table-row:hover > td {
    background: #303030;
  }
  .ant-notification .ant-table .ant-table-filter-trigger-container-open {
    background-color: #525252;
  }
  .ant-notification .ant-picker-calendar-full {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-picker-calendar-full .ant-picker-panel {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #1f1f1f;
  }
  .ant-notification .ant-badge-count {
    box-shadow: 0 0 0 1px #1f1f1f;
  }
  .ant-notification .ant-tree-show-line .ant-tree-switcher {
    background: #1f1f1f;
  }
  .ant-notification {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-notification-notice {
    background: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-notification-notice-message {
    color: rgba(255, 255, 255, 0.85);
  }
  .anticon.ant-notification-notice-icon-success {
    color: #49aa19;
  }
  .anticon.ant-notification-notice-icon-info {
    color: #177ddc;
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #d89614;
  }
  .anticon.ant-notification-notice-icon-error {
    color: #a61d24;
  }
  .ant-notification-notice-close {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-notification-notice-close:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-page-header {
    color: rgba(255, 255, 255, 0.85);
    background-color: #141414;
  }
  .ant-page-header-back-button {
    color: #177ddc;
  }
  .ant-page-header-back-button:focus,
  .ant-page-header-back-button:hover {
    color: #165996;
  }
  .ant-page-header-back-button:active {
    color: #388ed3;
  }
  .ant-page-header-heading-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-page-header-heading-sub-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-progress {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-progress-steps-item {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-progress-steps-item-active {
    background: #177ddc;
  }
  .ant-progress-inner {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-progress-circle-trail {
    stroke: rgba(255, 255, 255, 0.08);
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #177ddc;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #177ddc;
  }
  .ant-progress-success-bg {
    background-color: #49aa19;
  }
  .ant-progress-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-progress-status-active .ant-progress-bg::before {
    background: #141414;
  }
  .ant-progress-status-exception .ant-progress-bg {
    background-color: #a61d24;
  }
  .ant-progress-status-exception .ant-progress-text {
    color: #a61d24;
  }
  .ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #a61d24;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: #49aa19;
  }
  .ant-progress-status-success .ant-progress-text {
    color: #49aa19;
  }
  .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #49aa19;
  }
  .ant-progress-circle .ant-progress-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #a61d24;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #49aa19;
  }
  .ant-rate {
    color: rgba(255, 255, 255, 0.85);
    color: #d8bd14;
  }
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: rgba(255, 255, 255, 0.12);
  }
  .ant-result-success .ant-result-icon > .anticon {
    color: #49aa19;
  }
  .ant-result-error .ant-result-icon > .anticon {
    color: #a61d24;
  }
  .ant-result-info .ant-result-icon > .anticon {
    color: #177ddc;
  }
  .ant-result-warning .ant-result-icon > .anticon {
    color: #d89614;
  }
  .ant-result-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-result-subtitle {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-result-content {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-skeleton-header .ant-skeleton-avatar {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-content .ant-skeleton-title {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(255, 255, 255, 0.16) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
  }
  .ant-skeleton-element .ant-skeleton-button {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-element .ant-skeleton-avatar {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-element .ant-skeleton-input {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-element .ant-skeleton-image {
    background: rgba(190, 190, 190, 0.2);
  }
  .ant-skeleton-element .ant-skeleton-image-path {
    fill: #bfbfbf;
  }
  .ant-slider {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-slider-rail {
    background-color: #262626;
  }
  .ant-slider-track {
    background-color: #153450;
  }
  .ant-slider-handle {
    background-color: #141414;
    box-shadow: 0;
  }
  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
    box-shadow: 0 0 0 5px rgba(23, 125, 220, 0.12);
  }
  .ant-slider-handle:focus {
    box-shadow: 0 0 0 5px rgba(23, 125, 220, 0.12);
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #434343;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #16436e;
  }
  .ant-slider-mark-text {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-slider-mark-text-active {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-slider-dot {
    background-color: #141414;
  }
  .ant-slider-disabled .ant-slider-track {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  .ant-slider-disabled .ant-slider-handle,
  .ant-slider-disabled .ant-slider-dot {
    background-color: #141414;
  }
  .ant-statistic {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-statistic-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-statistic-content {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-icon .ant-steps-icon {
    color: #177ddc;
  }
  .ant-steps-item-tail::after {
    background: #303030;
  }
  .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-title::after {
    background: #303030;
  }
  .ant-steps-item-subtitle {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #177ddc;
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #177ddc;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #177ddc;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #177ddc;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #177ddc;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #177ddc;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #177ddc;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #a61d24;
  }
  .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #a61d24;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #a61d24;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #303030;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: #a61d24;
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #303030;
  }
  .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
    background: #a61d24;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
    color: #177ddc;
  }
  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
    color: #177ddc;
  }
  .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #177ddc;
  }
  .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-small .ant-steps-item-description {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
  .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
    background: rgba(0, 0, 0, 0.001);
  }
  .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    background: none;
  }
  .ant-steps-navigation .ant-steps-item::before {
    background-color: #177ddc;
  }
  .ant-switch {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ant-switch:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
  }
  .ant-switch-checked {
    background-color: #177ddc;
  }
  .ant-switch-inner {
    color: #fff;
  }
  .ant-switch-handle::before {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }
  .ant-switch-loading-icon {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-switch-checked .ant-switch-loading-icon {
    color: #177ddc;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #1d1d1d;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
    background-color: transparent !important;
  }
  .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #1d1d1d;
  }
  .ant-table {
    color: rgba(255, 255, 255, 0.85);
    background: #141414;
  }
  .ant-table-footer {
    color: rgba(255, 255, 255, 0.85);
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-table-thead > tr > th {
    color: rgba(255, 255, 255, 0.85);
    background: #1d1d1d;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #262626;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #111b26;
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #0e161f;
  }
  .ant-table-summary {
    background: #141414;
  }
  div.ant-table-summary {
    box-shadow: 0 -1px 0 #303030;
  }
  .ant-table-pagination {
    row-gap: 8px;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #303030;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: transparent !important;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #262626;
  }
  .ant-table-thead th.ant-table-column-sort::before {
    background-color: transparent !important;
  }
  td.ant-table-column-sort {
    background: rgba(255, 255, 255, 0.01);
  }
  .ant-table-column-sorter {
    color: #bfbfbf;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #177ddc;
  }
  .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #a6a6a6;
  }
  .ant-table-filter-trigger {
    color: #bfbfbf;
  }
  .ant-table-filter-trigger:hover {
    color: rgba(255, 255, 255, 0.45);
    background: #434343;
  }
  .ant-table-filter-trigger.active {
    color: #177ddc;
  }
  .ant-table-filter-dropdown {
    color: rgba(255, 255, 255, 0.85);
    background-color: #1f1f1f;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
  .ant-table-filter-dropdown-btns {
    background-color: #1f1f1f;
  }

  .ant-table-selection-extra .anticon {
    color: #bfbfbf;
  }
  .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .ant-table-row-expand-icon {
    color: #177ddc;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: #165996;
  }
  .ant-table-row-expand-icon:active {
    color: #388ed3;
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #1d1d1d;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #141414;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: #141414;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-last::before {
    background-color: transparent !important;
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
  }
  .ant-table-sticky-scroll {
    background: #fcfcfc;
  }
  .ant-table-sticky-scroll-bar {
    background-color: rgba(0, 0, 0, 0.35);
  }
  .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .ant-table-sticky-scroll-bar-active {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .ant-timeline {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-timeline-item-head {
    background-color: #141414;
  }
  .ant-timeline-item-head-blue {
    color: #177ddc;
  }
  .ant-timeline-item-head-red {
    color: #a61d24;
  }
  .ant-timeline-item-head-green {
    color: #49aa19;
  }
  .ant-timeline-item-head-gray {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    background: #1d1d1d;
  }
  .ant-transfer {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-transfer-disabled .ant-transfer-list {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-transfer-list-search-action {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-transfer-list-search-action .anticon {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-transfer-list-search-action .anticon:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-transfer-list-header {
    color: rgba(255, 255, 255, 0.85);
    background: #141414;
  }
  .ant-transfer-list-content-item-remove {
    color: #177ddc;
    color: #434343;
  }
  .ant-transfer-list-content-item-remove:focus,
  .ant-transfer-list-content-item-remove:hover {
    color: #165996;
  }
  .ant-transfer-list-content-item-remove:active {
    color: #388ed3;
  }
  .ant-transfer-list-content-item-remove:hover {
    color: #165996;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    background-color: #262626;
  }
  .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: #0e161f;
  }
  .ant-transfer-list-content-item-checked {
    background-color: #111b26;
  }
  .ant-transfer-list-content-item-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-transfer-list-body-not-found {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-tree-checkbox {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #177ddc;
  }
  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-select-tree-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-tree-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-tree-checkbox-group {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: #177ddc;
  }
  .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-tree {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
    background: #111b26;
  }
  .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-select-tree-switcher-loading-icon {
    color: #177ddc;
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #11263c;
  }
  .ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
    background-color: #177ddc;
  }
  .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #177ddc;
  }
  .ant-select-tree-show-line .ant-select-tree-switcher {
    background: #141414;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #fff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: #177ddc;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #fff;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
    color: #fff;
  }
  .ant-tree-checkbox {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #177ddc;
  }
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-tree-checkbox-disabled + span {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tree-checkbox-wrapper {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tree-checkbox-group {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #177ddc;
  }
  .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .ant-tree {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
    background: #111b26;
  }
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
    background: rgba(255, 255, 255, 0.08);
  }
  .ant-tree-switcher-loading-icon {
    color: #177ddc;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #11263c;
  }
  .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
    background-color: #177ddc;
  }
  .ant-tree .ant-tree-treenode.drop-container > [draggable] {
    box-shadow: 0 0 0 2px #177ddc;
  }
  .ant-tree-show-line .ant-tree-switcher {
    background: #141414;
  }
  .ant-typography {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-typography.ant-typography-secondary {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-typography.ant-typography-success {
    color: #49aa19;
  }
  .ant-typography.ant-typography-warning {
    color: #d89614;
  }
  .ant-typography.ant-typography-danger {
    color: #a61d24;
  }
  a.ant-typography.ant-typography-danger:active,
  a.ant-typography.ant-typography-danger:focus,
  a.ant-typography.ant-typography-danger:hover {
    color: #b33b3d;
  }
  .ant-typography.ant-typography-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  h1.ant-typography,
  .ant-typography h1 {
    color: rgba(255, 255, 255, 0.85);
  }
  h2.ant-typography,
  .ant-typography h2 {
    color: rgba(255, 255, 255, 0.85);
  }
  h3.ant-typography,
  .ant-typography h3 {
    color: rgba(255, 255, 255, 0.85);
  }
  h4.ant-typography,
  .ant-typography h4 {
    color: rgba(255, 255, 255, 0.85);
  }
  h5.ant-typography,
  .ant-typography h5 {
    color: rgba(255, 255, 255, 0.85);
  }

  a.ant-typography,
  .ant-typography a {
    color: #177ddc;
  }
  a.ant-typography:focus,
  .ant-typography a:focus,
  a.ant-typography:hover,
  .ant-typography a:hover {
    color: #165996;
  }
  a.ant-typography:active,
  .ant-typography a:active {
    color: #388ed3;
  }
  a.ant-typography[disabled],
  .ant-typography a[disabled],
  a.ant-typography.ant-typography-disabled,
  .ant-typography a.ant-typography-disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  a.ant-typography[disabled]:active,
  .ant-typography a[disabled]:active,
  a.ant-typography.ant-typography-disabled:active,
  .ant-typography a.ant-typography-disabled:active,
  a.ant-typography[disabled]:hover,
  .ant-typography a[disabled]:hover,
  a.ant-typography.ant-typography-disabled:hover,
  .ant-typography a.ant-typography-disabled:hover {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-typography code {
    background: rgba(150, 150, 150, 0.1);
  }
  .ant-typography kbd {
    background: rgba(150, 150, 150, 0.06);
  }
  .ant-typography mark {
    background-color: #594214;
  }
  .ant-typography-expand,
  .ant-typography-edit,
  .ant-typography-copy {
    color: #177ddc;
  }
  .ant-typography-expand:focus,
  .ant-typography-edit:focus,
  .ant-typography-copy:focus,
  .ant-typography-expand:hover,
  .ant-typography-edit:hover,
  .ant-typography-copy:hover {
    color: #165996;
  }
  .ant-typography-expand:active,
  .ant-typography-edit:active,
  .ant-typography-copy:active {
    color: #388ed3;
  }
  .ant-typography-copy-success,
  .ant-typography-copy-success:hover,
  .ant-typography-copy-success:focus {
    color: #49aa19;
  }
  .ant-typography-edit-content-confirm {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-typography pre {
    background: rgba(150, 150, 150, 0.1);
  }
  .ant-upload {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload.ant-upload-select-picture-card {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .ant-upload.ant-upload-drag {
    background: rgba(255, 255, 255, 0.04);
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #165996;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(255, 255, 255, 0.3);
  }
  .ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-list {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload-list-item-card-actions .anticon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-list-item-info .anticon-loading .anticon,
  .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-list-item .anticon-close {
    color: rgba(255, 255, 255, 0.45);
  }
  .ant-upload-list-item .anticon-close:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #a61d24;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: #a61d24;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #e6d9d8;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: #a61d24;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: rgba(255, 255, 255, 0.04);
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dark .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  }
}
