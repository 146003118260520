.login-bg {
    width: 100vw;
    background: url("../../assets/img/bg1.jpg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    text-align: center;
}

.login-wrap{display:inline-block; margin:0px auto; min-height: calc(100vh - 215px);}
.login-logo-wrap{
    position:relative;
    margin-bottom: 30px;
}
.login-wrap1{
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    padding: 20px;
}
.login-logo{
    img{height: 48px;}
}  
.login-title{font-size: 28px; 
    font-weight: bold; margin-left: 10px;
    position: relative;
    top: 4px;
}
.login-header .login-sub-title{
    font-size: 24px; margin-top: 15px; margin-bottom: 30px;
    opacity: 0.8;
}
@media screen and (min-height: 768px) {
    .login-wrap{
        margin: 50px 0px;
    }
}

@primary-color: #1DA57A;