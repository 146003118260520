@font-face {
  font-family: "iconfont"; /* Project id 2610525 */
  src: url('iconfont.woff2?t=1623680091410') format('woff2'),
       url('iconfont.woff?t=1623680091410') format('woff'),
       url('iconfont.ttf?t=1623680091410') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-system:before {
  content: "\e684";
}

.icon-wenjian:before {
  content: "\e68f";
}

.icon-wuliu:before {
  content: "\e606";
}

.icon-guojihua:before {
  content: "\e6e4";
}

.icon-rizhi:before {
  content: "\e69d";
}

.icon-dizhi:before {
  content: "\e62a";
}

.icon-tuihuozhong:before {
  content: "\e6cf";
}

.icon-menu-s:before {
  content: "\e653";
}

.icon-user:before {
  content: "\e626";
}

.icon-quanxian:before {
  content: "\e656";
}

.icon-shezhi:before {
  content: "\e611";
}

.icon-bianhao:before {
  content: "\e635";
}

.icon-jiaoseguanli:before {
  content: "\e72c";
}

